import breeam1 from '../../../static/images/icons/breeam.png'
import breeam2 from '../../../static/images/icons/breeam.png'
import castellana from "../../../static/images/utopicus/utopicus_castellana.jpg"
import habana from "../../../static/images/utopicus/paseo_habana.jpg"
import vergara from '../../../static/images/utopicus/principe_vergara.jpg'
import abascal from '../../../static/images/utopicus/jose_abascal.jpg'
import cibeles from '../../../static/images/utopicus/gran-via.jpg'
import silvela from '../../../static/images/utopicus/francisco_silvela.jpg'
import orense from '../../../static/images/utopicus/orense.jpg'
import marenostrum from '../../../static/images/utopicus/torre_marenostrum.jpg'
import diagonal from '../../../static/images/utopicus/diagonal.jpg'
import placidia from '../../../static/images/utopicus/placidia.jpg'
import catalunya from '../../../static/images/utopicus/placa_catalunya.jpg'
import granada from '../../../static/images/utopicus/utopicus_glories.jpg'

const utopicusData = [
    {
        id: "1",
        name: "Utopicus Castellana",
        address: "Paseo de la castellana, 163",
        img_path: "utopicus/utopicus_castellana.jpg", 
        img: castellana,
        new: "Madrid",
        active: "Utopicus Castellana",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 40.46175492889401, lng: -3.6912026000842606 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "2",
        name: "Utopicus P. Habana",
        address: "Paseo de la habana, 9-11",
        img_path: "utopicus/paseo_habana.jpg",
        img: habana,
        new: "Madrid",
        active: "Utopicus P. Habana",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 40.449853617608746, lng: -3.6894908171433443 },
          bimCoordination: "https://docs.b360.autodesk.com/projects/84e3bc50-7b61-455d-a357-852336e296fb/folders/urn:adsk.wipprod:fs.folder:co.0YJzC6BbTWiR-Rr0FlNTxg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:K8HGesJgTqu5hdPhVBa3Lw",
          bimArchitecture: "https://docs.b360.autodesk.com/projects/84e3bc50-7b61-455d-a357-852336e296fb/folders/urn:adsk.wipprod:fs.folder:co.0YJzC6BbTWiR-Rr0FlNTxg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:K8HGesJgTqu5hdPhVBa3Lw",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: 'https://docs.b360.autodesk.com/projects/84e3bc50-7b61-455d-a357-852336e296fb/folders/urn:adsk.wipprod:fs.folder:co.GmTaG_6USdeqc3NFs2RVzQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:dV-XHln8QTKpR6HzXBLVrQ',
          commercialRenders: 'https://docs.b360.autodesk.com/projects/84e3bc50-7b61-455d-a357-852336e296fb/folders/urn:adsk.wipprod:fs.folder:co.MwbnivaGSruxASzpZGzAKw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:rroTH-ZKQbKAOyJ5WtCrBw',
          commercialAR: '#',
          tracingScan: "https://aec.cintoo.com/393F3B3D1B373A390D3A",
      },
      {
        id: "3",
        name: "Utopicus Pcpe. Vergara",
        address: "C/ Príncipe de vergara, 112",
        img_path: "utopicus/pricipe_vergara.jpg",
        img: vergara,
        new: "Madrid",
        active: "Utopicus Pcpe. Vergara",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 40.43983862881558, lng: -3.6783426467559583 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "4",
        name: "Utopicus José Abascal",
        address: "C/ José abascal, 56",
        img_path: "utopicus/jose_abascal.jpg",
        img: abascal,
        new: "Madrid",
        active: "Utopicus José Abascal",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 40.4382299163922, lng: -3.692385717920339 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "5",
        name: "Utopicus Gran Vía",
        address: "Gran vía, 4",
        img_path: "utopicus/gran-via.jpg",
        img: cibeles,
        new: "Madrid",
        active: "Utopicus Gran Vía Cibeles",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 40.4195033809804, lng: -3.6975311430569247 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "6",
        name: "Francisco Silvela",
        address: "C/ Francisco silvela, 42",
        img_path: "utopicus/francisco_silvela.jpg",
        img: silvela,
        new: "Madrid",
        active: "Utopicus Francisco Silvela",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 40.433637338071904, lng: -3.6729737314134114 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "7",
        name: "Utopicus Orense",
        address: "C/ Orense, 62",
        img_path: "utopicus/orense.jpg",
        img: orense,
        new: "Madrid",
        active: "Utopicus Orense",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 40.46075451878074, lng: -3.6937491295626708 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "8",
        name: "Utopicus Torre Marenostrum",
        address: "Pl. del gas, 1, Edificio B",
        img_path: "utopicus/torre_marenostrum.jpg", 
        img: marenostrum,
        new: "Barcelona",
        active: "Utopicus Torre Marenostrum",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 41.383553110008414, lng: 2.1902600352951023 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "9",
        name: "Utopicus Diagonal",
        address: "Avenida diagonal, 532",
        img_path: "utopicus/diagonal.jpg", 
        img: diagonal,
        new: "Barcelona",
        active: "Utopicus Diagonal",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 41.39513084167029, lng: 2.152223955125319 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "10",
        name: "Utopicus Placídia",
        address: "Plaça Gal la placidia 1-3",
        img_path: "utopicus/placidia.jpg", 
        img: placidia,
        new: "Barcelona",
        active: "Utopicus Placídia",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 41.39875647889227, lng: 2.1529593416325605 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "11",
        name: "Utopicus Plaça Catalunya",
        address: "C/ Ronda sant pere, 16",
        img_path: "utopicus/placa_catalunya.jpg", 
        img: catalunya,
        new: "Barcelona",
        active: "Utopicus Plaça Catalunya",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 41.3886861547998, lng: 2.1719120667682064 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
      {
        id: "12",
        name: "Utopicus Glóries",
        address: "Carrer de la ciutat de granada, 150",
        img_path: "utopicus/utopicus_glories.jpg", 
        img: granada,
        new: "Barcelona",
        active: "Utopicus Glóries",
        bream1: "Bream Parte I: Good",
        bream2: "Bream Parte II: Very Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 41.400725068306876, lng: 2.1944810993039656 },
          bimCoordination: "#",
          bimArchitecture: "#",
          bimInstallations: "#",
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimCad: '#',
          commercialFlats: '#',
          commercialRenders: '#',
          commercialAR: '#',
          tracingScan: "#",
      },
]
export default utopicusData